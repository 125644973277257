import { CircularProgress, withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { confirmUserForgotPasswordOnProvider } from "../../../api/users.ts";
import { CustomButtonOutLine } from "../../../components/custom-button";
import { primaryColor, secondaryColor } from "../../../scss/colors.scss";
import { checkEmailVerification } from "../../../utils/email-verification";
import { cognitoForgotPassword } from "../../../utils/user-auth-provider";

const CssTextField = withStyles({
  root: {
    width: "100%",
    marginTop: "2rem",
    marginBottom: "2rem",
    display: "flex",
    "& label.Mui-focused": {
      color: "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: secondaryColor,
      },
      "&:hover fieldset": {
        borderColor: secondaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: secondaryColor,
      },
    },
  },
})(TextField);

const EmailInputComponent = ({ handleInput = null }) => {
  return (
    <Formik
      initialValues={{ email: "" }}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = "Required";
        } else if (checkEmailVerification(values.email)) {
          errors.email = "Invalid email address";
        }
        return errors;
      }}
      onSubmit={(values, { setFieldError }) => {
        const params = { email: values.email };
        const response = confirmUserForgotPasswordOnProvider(params);
        return response
          .then(() => {
            cognitoForgotPassword(params.email).then((result) => {
              const { result: responseResult = "" } = result;
              responseResult === "OK" && handleInput("Success");
              responseResult === "NOT_FOUND" &&
                setFieldError(
                  "email",
                  "Your email does not exist on our system."
                );
              navigate("/recover-password", { state: { email: values.email } });
            });
          })
          .catch(() =>
            setFieldError("email", "Something went wrong. Please try again.")
          );
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form className="login-form" onSubmit={handleSubmit}>
          <CssTextField
            label="Email"
            type="email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            variant="outlined"
            id="custom-css-outlined-input"
            helperText={errors.email}
            error={!!errors.email}
          />
          <div className="submit-button-group">
            <button
              className="login-submit-button custom-button-fill"
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? (
                <CircularProgress size={14} color="inherit" />
              ) : (
                "Reset"
              )}
            </button>

            <CustomButtonOutLine
              disabled={isSubmitting}
              label="Login"
              onClick={() => navigate("/login")}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};

EmailInputComponent.propTypes = {
  handleInput: PropTypes.func,
};

export default EmailInputComponent;
